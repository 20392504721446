import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import CTA from "../components/CTA"
import PageHeading from "../components/PageHeading"
import { Content } from "../components/Content"

type TemplateProps = {
  page: {
    title: string
    subtitle: string
    heroImage: string
    firstSection: {
      heading: string
      support: string
      content: string
    }
    callToAction: {
      heading: string
      subHeading: string
      action: string
    }
  }
  clients: {
    logo1: string
    logo2: string
    logo3: string
    logo4: string
    logo5: string
    logo6: string
  }
  contact: {
    address: string
    phone: string
    email: string
  }
}

export function QuienesSomosPageTemplate({
  page,
  clients,
  contact,
}: TemplateProps) {
  const { title, subtitle, heroImage, firstSection, callToAction } = page
  return (
    <>
      <section className="about-hero bg-gradient-to-br from-green-300 via-green-500 to-green-700 md:pb-16">
        <div className="flex flex-col w-full max-w-5xl px-8 mx-auto mt-32 md:flex-row-reverse md:px-2 about-hero">
          <div className="w-full md:w-1/2 md:pl-6">
            <p className="mt-6 text-lg font-semibold text-orange-500">
              Compromiso, cumplimiento y calidad
            </p>
            <div className="mt-4">
              <PageHeading>{title}</PageHeading>
            </div>
            <p className="max-w-sm mt-6 text-lg text-green-100">{subtitle}</p>
            <div className="mt-6">
              <CTA url="/contactanos">Solicita una cotización</CTA>
            </div>
          </div>
          <div className="flex-shrink w-full md:w-1/2">
            <img
              src={heroImage}
              alt="Imagen de aliados"
              className="object-cover max-w-full mx-auto mt-16 w-128 md:w-auto md:max-w-md md:mx-0 md:mt-0"
              style={{ height: 512 }}
            />
          </div>
        </div>
        <div className="bg-cut"></div>
      </section>
      {/* Who we are section */}
      <section className="flex flex-col w-full max-w-5xl px-8 mx-auto -mt-12 md:px-2 md:flex-row md:mt-16">
        <div className="w-full md:w-1/2">
          <p className="font-semibold text-orange-500">
            {firstSection.support}
          </p>
          <h2 className="mt-1 text-3xl font-bold leading-9 font display">
            {firstSection.heading}
          </h2>
        </div>
        <div className="w-full md:w-1/2">
          <Content
            content={firstSection.content}
            className="mt-6 cms-content md:mt-0"
          />
        </div>
      </section>
      {/* Our clients section */}
      <section className="mt-10 overflow-hidden section-container md:mt-16">
        <hr className="separator" />

        <h2 className="text-4xl font-bold leading-tight text-green-600 md:text-5xl font-display">
          Nuestros clientes
        </h2>
        <p className="text-lg leading-6 text-gray-700 w-96">
          Empresas que ya confían en nostoros
        </p>

        <div className="flex justify-between max-w-full mt-16 space-x-6 overflow-x-auto">
          <div className="w-32 text-gray-50" style={{ minWidth: "8rem" }}>
            <img
              className="object-contain w-full h-24"
              src={clients.logo1}
              alt="Company logo 1"
            />
          </div>
          <div className="w-32 text-gray-500" style={{ minWidth: "8rem" }}>
            <img
              className="object-contain w-full h-24"
              src={clients.logo2}
              alt="Company logo 2"
            />
          </div>
          <div className="w-32 text-gray-500" style={{ minWidth: "8rem" }}>
            <img
              className="object-contain w-full h-24"
              src={clients.logo3}
              alt="Company logo 3"
            />
          </div>
          <div className="w-32 text-gray-500" style={{ minWidth: "8rem" }}>
            <img
              className="object-contain w-full h-24"
              src={clients.logo4}
              alt="Company logo 4"
            />
          </div>
          <div className="w-32 text-gray-500" style={{ minWidth: "8rem" }}>
            <img
              className="object-contain w-full h-24"
              src={clients.logo5}
              alt="Company logo 5"
            />
          </div>
          <div className="w-32 text-gray-500" style={{ minWidth: "8rem" }}>
            <img
              className="object-contain w-full h-24"
              src={clients.logo6}
              alt="Company logo 6"
            />
          </div>
        </div>
      </section>
      {/* End Our clients section */}
      {/* Locations sections */}
      <section className="mx-auto mt-10 overflow-hidden section-container md:mt-48">
        <hr className="separator" />

        <h2 className="text-4xl font-bold leading-tight text-green-600 md:text-5xl font-display">
          Encuéntranos
        </h2>
        <div className="flex flex-col mt-4 md:flex-row-reverse">
          <div className="w-full md:w-1/2">
            <iframe
              className="w-full h-64 border-0 md:h-96"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5624.030069223507!2d-74.16125821293566!3d4.6315555424262564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfdf273e054969685!2sCorabastos!5e0!3m2!1sen!2sco!4v1595092307893!5m2!1sen!2sco"
              frameBorder="0"
              allowFullScreen
              aria-hidden="false"
              tabIndex={0}
            ></iframe>
          </div>
          <div className="w-full mt-6 md:w-1/2 md:mt-0">
            <h3 className="text-xl font-bold text-gray-700">
              Bogotá - Corabastos
            </h3>
            <ul className="mt-2 space-y-2 list-none">
              <li className="flex flex-row items-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  className="inline w-4 h-4 mt-1 text-gray-500 fill-current stroke-current"
                >
                  <path
                    fill="currentColor"
                    d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                  ></path>
                </svg>
                <span className="ml-2 text-gray-700 hover:text-gray-800 focus:text-gray-800">
                  {contact.address}
                </span>
              </li>
              <li className="flex flex-row items-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="inline w-4 h-4 text-gray-500 fill-current stroke-current"
                >
                  <path
                    fill="currentColor"
                    d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"
                    className=""
                  ></path>
                </svg>
                <span className="ml-2 text-gray-700 hover:text-gray-800 focus:text-gray-800">
                  <a href={`tel:${contact.phone.replace(/[\(\)\s]/g, "")}`}>
                    {contact.phone}
                  </a>
                </span>
              </li>
              <li className="flex flex-row items-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="envelope"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="inline w-4 h-4 text-gray-500 fill-current stroke-current"
                >
                  <path
                    fill="currentColor"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                    className=""
                  ></path>
                </svg>
                <span className="ml-2 text-gray-700 hover:text-gray-800 focus:text-gray-800">
                  <a href="mailto:contacto@grancolmado.com.co">
                    contacto@grancolmado.com.co
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex justify-between max-w-full mt-16 space-x-6 overflow-x-auto"></div>
      </section>
      {/*End Locations sections */}
      {/* CTA */}
      <div className="Section__masked">
        <div className="Section__backgroundMask">
          <div className="Section__background"></div>
        </div>
        <div className="Section__container">
          <div className="w-full py-12 mt-12 mb-16 md:py-24">
            <div className="flex flex-col items-center w-full max-w-5xl px-2 mx-auto md:px-8 md:flex-row">
              <div className="flex-1">
                <h2 className="text-4xl font-bold leading-10 text-center text-orange-500 font-display md:text-left">
                  {callToAction.heading}
                </h2>
                <p className="mt-4 text-center text-gray-700 md:text-left">
                  {callToAction.subHeading}
                </p>
              </div>
              <div className="flex justify-end flex-1 mt-6 md:mt-0">
                <CTA url="/contactanos">{callToAction.action}</CTA>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End CTA */}
    </>
  )
}

type PageProps = {
  data: any
}

export default function QuienesSomosPage({ data }: PageProps) {
  const { frontmatter: page } = data.pageData.edges[0].node
  const clients = data.homePageData.edges[0].node.frontmatter.clients
  const contact = data.contactData.edges[0].node.frontmatter

  return (
    <Layout>
      <SEO title={page.seo.title} description={page.seo.description} />
      <QuienesSomosPageTemplate
        page={page}
        clients={clients}
        contact={contact}
      />
    </Layout>
  )
}

export const quienesSomoPageQuery = graphql`
  query quienesSomosPageQuery {
    pageData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "quienes-somos-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            heroImage
            firstSection {
              heading
              support
              content
            }
            callToAction {
              heading
              subHeading
              action
            }
            seo {
              title
              description
            }
          }
        }
      }
    }
    homePageData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            clients {
              logo1
              logo2
              logo3
              logo4
              logo5
              logo6
            }
          }
        }
      }
    }
    contactData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "contact-data" } } }
    ) {
      edges {
        node {
          frontmatter {
            address
            name
            description
            email
            phone
          }
        }
      }
    }
  }
`
